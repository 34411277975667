import * as React from 'react';
import { graphql } from 'gatsby';

import Page from '../components/Page';
import Container from '../components/Container';
import IndexLayout from '../layouts';

interface PageTemplateProps {
	data: {
		site: {
			siteMetadata: {
				title: string
				description: string
				author: {
					name: string,
				},
			},
		},
		markdownRemark: {
			html: string
			excerpt: string
			frontmatter: {
				title: string,
			},
		},
	};
}

const PageTemplate: React.FunctionComponent<PageTemplateProps> = ({ data }) => (
  <IndexLayout>
	<Page>
		<Container>
		<h1>{data.markdownRemark.frontmatter.title}</h1>
		<div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
		</Container>
	</Page>
  </IndexLayout>
);

export default PageTemplate;

export const query = graphql`
  query PageTemplateQuery($slug: String!) {
    site {
      siteMetadata {
        title
        description
        author {
          name
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        title
      }
    }
  }
`;
